/* tslint:disable */
/* eslint-disable */
/**
 * kiwi-api-dev
 * 走行計画、走行実績、全VEC予実管理, HDmap可視化、POI DB
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PoiMeta
 */
export interface PoiMeta {
    /**
     * 
     * @type {string}
     * @memberof PoiMeta
     */
    s3_filename: string;
    /**
     * 
     * @type {string}
     * @memberof PoiMeta
     */
    updated_at: string;
    /**
     * 
     * @type {string}
     * @memberof PoiMeta
     */
    updated_by: string;
    /**
     * 
     * @type {string}
     * @memberof PoiMeta
     */
    created_at: string;
    /**
     * 
     * @type {object}
     * @memberof PoiMeta
     */
    poi_id: object;
    /**
     * 
     * @type {string}
     * @memberof PoiMeta
     */
    title: string;
    /**
     * 
     * @type {string}
     * @memberof PoiMeta
     */
    created_by: string;
    /**
     * 
     * @type {string}
     * @memberof PoiMeta
     */
    line_flg: string;
}

export function PoiMetaFromJSON(json: any): PoiMeta {
    return PoiMetaFromJSONTyped(json, false);
}

export function PoiMetaFromJSONTyped(json: any, ignoreDiscriminator: boolean): PoiMeta {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        's3_filename': json['s3_filename'],
        'updated_at': json['updated_at'],
        'updated_by': json['updated_by'],
        'created_at': json['created_at'],
        'poi_id': json['poi_id'],
        'title': json['title'],
        'created_by': json['created_by'],
        'line_flg': json['line_flg']
    };
}

export function PoiMetaToJSON(value?: PoiMeta | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        's3_filename': value.s3_filename,
        'updated_at': value.updated_at,
        'updated_by': value.updated_by,
        'created_at': value.created_at,
        'poi_id': value.poi_id,
        'title': value.title,
        'created_by': value.created_by,
        'line_flg': value.line_flg
    };
}
