import React from 'react';
import { Tooltip as BootstrapTooltip } from 'react-bootstrap';

export const Tooltip = React.forwardRef(({
  children,
  ...otherProps
}, ref) => {

  return (
    <BootstrapTooltip ref={ref} {...otherProps}>
      {children}
    </BootstrapTooltip>
  )
})