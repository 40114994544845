import React from "react";
import { Badge } from "react-bootstrap";

// Line バッジ
export const BadgeLine = () => {
  return (
    <>
      <Badge style={{ marginRight: "4px" }} variant="primary">Line</Badge>
    </>
  );
};

// Point バッジ
export const BadgePoint = () => {
  return (
    <>
      <Badge style={{ marginRight: "4px" }} variant="danger">Point</Badge>
    </>
  );
};
